<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="90px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-tooltip class="item" effect="dark" content="商品名称将显示在列表中展示，方便商家管理使用" placement="top">
          <el-input v-model="form.name" style="width: 25%" size="small"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="备注" prop="details">
        <el-tooltip class="item" effect="dark" content="备注是商家对秒杀的补充说明文字，非必填选项" placement="top">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2}"
              placeholder="请输入内容"
              v-model="form.details"
              size="small"
              style="width: 25%"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间:" prop="begin_time">
        <el-tooltip class="item" effect="dark" content="商品只会在活动时间段内的指定场次进行展示" placement="top">
          <!-- :default-time="['00:00:00', '23:59:59']" -->
          <el-date-picker
              v-model="query_time"
              size="small"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              clearable
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="getTimeSection"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="参与场次" prop="seckill_session_id">
        <el-select
            v-model="form.seckill_session_id"
            clearable
            placeholder="请选择"
            size="small"
            multiple
            filterable
            style="width:25%"
        >
          <el-option
              v-for="item in seckillObj"
              :key="item.id"
              :label="item.name+'('+item.begin_date+'~'+item.end_date+')'"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="商品选择">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="seckill_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="限购(0为不限购)"
              prop="purchase_num"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.purchase_num" v-model="s_list[scope.$index].purchase_num"
                        placeholder="请输入内容" :value="scope.row.purchase_num"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].purchase_num" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="秒杀价"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容" :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="秒杀库存"
              prop="inventory"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.inventory" v-model="s_list[scope.$index].inventory"
                        placeholder="请输入内容" :value="scope.row.inventory"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].inventory" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" style="margin-top: 10px">选择商品</el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" style="margin-top: 10px" disabled>批量删除</el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import {apiGetSessionList, apiSeckillInfo, apiUpdateSeckill} from "@/request/api";
import PopView from "@/views/components/popView";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      listLoading: false,
      selectRows: '',
      showPop: false,
      seckillObj: [],
      query_time: [],
      s_list: [],
      form: {
        id: '',
        name: '',
        details: '',
        begin_time: '',
        end_time: '',
        seckill_session_id: [],
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名称不能为空'},
        ],
        details: [
          {required: true, trigger: 'blur', message: '活动简介不能为空'},
        ],
        begin_time: [
          {required: true, trigger: 'blur', message: '活动时间不能为空'},
        ],
        seckill_session_id: [
          {required: true, trigger: 'blur', message: '参与场次不能为空'},
        ],
      },
    }
  },
  created() {
    this.getSeckill()
    if (this.$route.query.id) {
      this.seckillInfo()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    seckillInfo() {
      apiSeckillInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          this.form = {
            id: res.data.id,
            name: res.data.name,
            details: res.data.details,
            begin_time: this.formatDate(res.data.begin_time),
            end_time: this.formatDate(res.data.end_time),
            seckill_session_id: res.data.seckill_session_id.map(Number),
            goods: this.getGoods(res.data.goods),
          }
          this.query_time = [this.formatDate(res.data.begin_time), this.formatDate(res.data.end_time)]
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          this.list.push({
            sku_id: arr[i].sku_id,
            purchase_num: arr[i].purchase_num,
            price: arr[i].price,
            inventory: arr[i].inventory
          })
          this.s_list.push({
            sku_id: arr[i].sku_id,
            name: arr[i].info.name,
            seckill_price: arr[i].info.price,
            stock: arr[i].info.stock,
            purchase_num: arr[i].purchase_num,
            price: arr[i].price,
            inventory: arr[i].inventory
          })
        }
        return this.list
      }
    },
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    showPopSubmit(e) {
      this.showPop = true;
      this.listLoading = true
      // this.list = this.form.goods
      console.log(e)
      e.forEach(item => {

        this.s_list.push({
          sku_id: item.skuid,
          name: item.name,
          seckill_price: item.price,
          stock: item.stock,
          purchase_num: 1,
          price: item.price,
          inventory: item.stock
        })
      })
      const smap = new Map()
      // 对id属性进行去重
      this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
      this.listLoading = false
      console.log(this.s_list)
    },
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    //获取场次列表
    async getSeckill() {
      apiGetSessionList({type: 0}).then(res => {
        if (res.code == 200) {
          this.seckillObj = res.data
        }
      })
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.s_list = []
    },
    //提交保存
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            purchase_num: item.purchase_num,
            price: item.price,
            inventory: item.inventory
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiUpdateSeckill(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
